<template>
  <v-tooltip
    bottom
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        :small="small"
        :class="btnClass"
        :fab="fab"
        :color="color"
        :dark="dark"
        :rounded="rounded"
        :outlined="outlined"
        @click="$emit('click')"
      >
        <slot></slot>
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>
<script>
export default {
  name: 'TooltipBtn',
  props: {
    btnClass: { type: String, default: '' },
    rounded: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    tooltip: { type: String, default: 'ToolTip' },
    fab: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    color: { type: String, default: 'primary' },
    dark: { type: Boolean, default: true },
  },
}
</script>
